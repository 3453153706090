import React from "react";
import Layout from "./../../components/Layout";
import CommunityHero from "./../../components/CommunityHero";
import TeamCardHolder from "./../../components/TeamCardHolder";
import { useStaticQuery, graphql } from "gatsby";

// N.b. this is very similar to what's in templates/teamPage! But not exactly the same.
// If there are changes, make sure they're reflected!

const TeamFrontPage = () => {
  const teamQuery = useStaticQuery(graphql`
    query {
      people: allCraftOurPeopleOurPeopleEntry(sort: {order: ASC, fields: positionOfMember}) {
        nodes {
          title
          ourPeopleHeadshot {
            ... on Craft_ourPeopleAssets_Asset {
              wideImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 295) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          slug
          ourPeopleFacebook
          ourPeopleTwitter
          ourPeopleTitle
          ourPeopleLinkedin
          ourPeopleInstagram
          richTextField
          communityMemberContactDetails {
            method
            value
          }
        }
      }
      recommendations: allCraftRecommendationRecommendationEntry {
        edges {
          node {
            title
            recommendationName {
              slug
              title
            }
            recommendationEvents {
              ... on Craft_event_event_Entry {
                title
                eventEndDateTime
                eventStartDateTime
                eventShortDescription
                slug
                eventType
                headerImage {
                  ... on Craft_editorialAssets_Asset {
                    cardImage: localFile {
                      publicURL
                      childImageSharp {
                        fixed(width: 290) {
                          src
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      teamPage: craftTeamTeamEntry {
        title
        subtitle
      }
    }
  `);
  const ourPeople = teamQuery.people.nodes;

  const allTheRecommendations = teamQuery.recommendations.edges.map(
    (x) => x.node
  );
  const { title, subtitle } = teamQuery.teamPage;
  // console.log(ourPeople, allTheRecommendations);

  return (
    <Layout backgroundColor={"var(--white)"}>
      <div>
        <CommunityHero topLine={title} text={subtitle} color={"var(--green)"} />
        <TeamCardHolder
          cards={ourPeople}
          recommendations={allTheRecommendations}
        />
      </div>
    </Layout>
  );
};

export default TeamFrontPage;
